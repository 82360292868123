@import "fonts/fonts";

/** GENERAL STYLES **/
.layout-main {
	min-height: 0vh;

	.avatar {
		border-radius: 50%;
	}

	.layout-topbar-logo {
		display: inline-block;
		vertical-align: middle;
		background-image: url("images/biorec_owl.svg");
		background-position: bottom left;
		background-size: 8em;
		width: 8em;
		height: 4.1em;

		&.light {
			background-position: top left;
		}
		&.powered {
			background-image: url("images/biorec_owl_powered.svg");
		}
	}
}

.layout-sidebar {
	.logo {
		display: inline-block;
		vertical-align: middle;
		background-image: url("images/biorec_owl.svg");
		background-position: bottom left;
		background-size: 110px;
		height: 60px;
		width: 50px;

		&.powered {
			background-image: url("images/biorec_owl_powered.svg");
		}

		&.light {
			background-position: top left;
		}

		&.full {
			background-size: 140px;
			width: 140px;
			height: 75px;
		}
	}
}

@media screen and (max-width: 480px) {
	.layout-content {
		padding: 0.5rem;
	}
}

.pointer {
	cursor: pointer;
}

.module-title {
	text-align: center;
	border: 5px;
	border-radius: 5px;
	background: var(--primary-color);
	color: var(--primary-color-text);
	font-weight: bold;
	height: 40px;
	padding: 10px;
}

.module-title-small {
	text-align: center;
	border: 5px;
	border-radius: 5px;
	background: var(--primary-color);
	color: var(--primary-color-text);
	font-weight: bold;
	height: 25px;
	padding: 2px;
}

.cursor-pointer {
	cursor: pointer;
}

.p-accordion {
	.p-accordion-header {
		.p-accordion-header-link,
		&:not(.p-disabled).p-highlight .p-accordion-header-link,
		&:not(.p-disabled).p-highlight:hover .p-accordion-header-link,
		&:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link {
			background: var(--primary-color);
			color: var(--primary-color-text);
		}
		&:not(.p-disabled).p-highlight:hover .p-accordion-header-link,
		&:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link {
			/*font-size: 1.1rem;*/
		}
	}

	p-accordiontab .p-accordion-tab {
		margin-bottom: 1px;
	}
}

label {
	font-size: 12px;
	color: var(--text-color-secondary);
}

.p-dialog .p-dialog-content.whiteDialog {
	background: white;
}

.clearFormatting {
	all: initial;
}

.clearFormatting p {
	margin: 0;
	line-height: 1rem;
}

.mdi:before {
	font-size: 1.4rem;
}

#loading {
	background-color: rgba(#000000, 0.5);
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	position: fixed;
	z-index: 10000;
}

.required:after,
.p-button[required] .p-button-label:after {
	content: "*";
	color: var(--error-color);
	padding: 0 5px;
}

.ng-touched.ng-invalid:not(form) {
	&.p-component,
	&.p-element .p-component {
		border-color: var(--error-color);
	}
}

.p-float-label,
.ep-static-label {
	& > .ng-touched.ng-invalid:not(form) + label {
		color: var(--error-color);
	}
	& > [required] + label:after {
		content: "*";
		color: var(--error-color);
		padding: 0 5px;
	}
}

.errorMessage {
	font-size: 12px;
	color: var(--error-color);
}
.profile-image.p-avatar {
	width: 5rem;
	height: 5rem;
}

.profile-image.p-avatar span:before {
	font-size: 5rem;
	vertical-align: bottom;
}

button.p-tabview-nav-btn.p-link {
	color: var(--text-color-secondary);
	background-color: var(--surface-a);
	border: 1px solid var(--surface-d);
}

.p-button.tab-button,
.p-button.tab-button:enabled:hover {
	color: var(--text-color-secondary);
	background-color: var(--surface-a);
	border: 1px solid var(--surface-d);
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

.p-tabview.tab-container .p-tabview-panels {
	background: transparent;
}

.tag-container.p-buttonset .p-button {
	background: none;
	border: none;
}

.tag-container.p-buttonset .p-button:not(.p-disabled):not(.p-highlight):hover {
	background-color: var(--surface-c);
}

.tag-container .tag {
	color: black;
	display: inline-block;
	padding: 5px 10px 5px 10px;
	border-radius: 5px;
	border: 1px solid #666666;
}

tr.inactive td,
li.p-treenode.inactive span,
li.p-dropdown-item.inactive span,
tr.viewed td {
	color: var(--text-color-secondary);
}

tr.inactive:not(:hover) {
	background-color: var(--surface-50);
}

tr.important:not(:hover) td {
	color: #721c24;
}

tr.important:not(:hover) {
	background-color: #f8d7da;
}

.inherited {
	color: var(--surface-400) !important;
}

br-images-groups-tree p-listbox .p-listbox {
	.p-listbox-list {
		display: flex;
		flex-wrap: wrap;
		box-sizing: border-box;
	}

	@media screen and (min-width: 576px) {
		.p-listbox-item {
			width: 50%;
			justify-content: center;
		}
	}
}

.exams-templates-select-dialog p-listbox .p-listbox {
	.p-listbox-list {
		display: flex;
		flex-wrap: wrap;
		box-sizing: border-box;
	}

	@media screen and (min-width: 576px) {
		.p-listbox-item {
			min-width: 50%;
		}
	}
}

.examiner-select-component p-listbox .p-listbox-list-wrapper  {
		min-height: 100% !important;
		max-height: none !important;
}

table.p-datatable-table > thead.p-datatable-thead > tr {
	& > th.single-button-column {
		width: 50px !important;
		max-width: 50px !important;
	}
}

.input-number-height-fix {
	height: 33px !important;
}
.icon-container {
	position: absolute;
	left: 0.8rem;
	top: 50%;
	transform: translateY(-50%);
	z-index: 1;
}

.icon-container i {
	display: block;
}

.doctor-link {
	cursor: pointer;
	color: #007bff;
}

.doctor-link:hover {
	color: #0056b3;
}

// confirm dialog styles start
.custom-confirm-dialog {
	text-align: center;

	.p-dialog-header {
		max-height: 50px;
		min-width: 350px;

		& > button {
			margin-right: -1.2rem;
			margin-top: -1.1rem;
			width: 2rem;
			height: 2rem;
			color: #6c757d;
			border: 0 none;
			background: transparent;
			border-radius: 50%;
			transition: box-shadow 0.15s;
		}
	}

	.p-confirm-dialog .p-dialog-content {
		display: flex;
		align-items: center;
		text-align: center;
		justify-content: center;
	}
}
// confirm dialog styles end

// FREESCOUT STYLES START
button.freescout {
	background: var(--primary-color);
	color: var(--primary-color-text);
	position: fixed;
	bottom: 12px;
	right: 19px;
	z-index: 1099;
}

iframe.freescout {
	width: 342px;
	height: 500px;
	background-color: #ffffff;
	position: fixed;
	bottom: 16px;
	right: 16px;
	max-height: calc(100vh - 32px);
	box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 0.428571rem 0px;
	overflow: hidden;
	border: 0;
	z-index: 1099;
	border-radius: 10px;

	@media screen and (max-width: 760px) {
		bottom: 0px;
		right: 0px;
		width: 100%;
		height: 100%;
		max-height: none;
		border-radius: 0px;
	}
}
// FREESCOUT STYLES END

