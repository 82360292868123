@CHARSET "UTF-8";

/* GENERAL START */
@font-face {
	font-family: 'open sans';
	font-style: normal;
	font-weight: normal;
	src: url('opensans/open-sans.regular.ttf') format('truetype');
}

@font-face {
	font-family: 'open sans';
	font-style: normal;
	font-weight: bold;
	src: url('opensans/open-sans.bold.ttf') format('truetype');
}

@font-face {
	font-family: 'open sans';
	font-style: italic;
	font-weight: normal;
	src: url('opensans/open-sans.italic.ttf') format('truetype');
}

@font-face {
	font-family: 'open sans';
	font-style: italic;
	font-weight: bold;
	src: url('opensans/open-sans.semibold-italic.ttf') format('truetype');
}

@font-face {
	font-family: 'arial';
	font-style: normal;
	src: url('arial/Arial.ttf') format('truetype');
}

@font-face {
	font-family: 'arial';
	font-style: italic;
	src: url('arial/Arial_Italic.ttf') format('truetype');
}

@font-face {
	font-family: 'arial';
	font-style: normal;
	font-weight: bold;
	src: url('arial/Arial_Bold.ttf') format('truetype');
}

@font-face {
	font-family: 'arial';
	font-style: italic;
	font-weight: bold;
	src: url('arial/Arial_Bold_Italic.ttf') format('truetype');
}

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	src: url('roboto/Roboto-Regular.ttf') format('truetype');
}

@font-face {
	font-family: 'Roboto';
	font-style: italic;
	src: url('roboto/Roboto-Italic.ttf') format('truetype');
}

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: bold;
	src: url('roboto/Roboto-Bold.ttf') format('truetype');
}

@font-face {
	font-family: 'Roboto';
	font-style: italic;
	font-weight: bold;
	src: url('roboto/Roboto-BoldItalic.ttf') format('truetype');
}

.fa {
		font-size: 24px;
}

.fa:before {
		display: inline-block;
		font: normal normal 900 24px/1 "Font Awesome 5 Free";
		font-size: inherit;
		text-rendering: auto;
		line-height: inherit;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
}

.mdi-empty {
	display: inline-block;
	width: 1em;
}
